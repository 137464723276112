import React, { FC } from 'react';

import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';
import MappedPhones from 'components/shared/mapped-phones';
import MailLink from 'components/shared/mail-link';
import IconWithBackground from 'components/shared/icon-with-background';
import styled, { css } from 'styled-components';
import { MD, SM, XS } from 'src/shared/media-query';
import theme from 'src/shared/theme';
import { graphql, useStaticQuery } from 'gatsby';
import { Link, useMediaQuery } from '@material-ui/core';
import { Variant } from '@material-ui/core/styles/createTypography';
import { Facebook as FacebookIcon } from '@material-ui/icons';
import Text from './text';

interface Props {
  className?: string;
  titleVariant?: Variant;
  shrinkIcons?: boolean;
}

const Block = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  margin-right: ${theme.spacing(4)};
  margin-bottom: ${theme.spacing(4)};

  @media ${XS} {
    margin-right: ${theme.spacing(8)};
    margin-bottom: ${theme.spacing(8)};
  }

  @media ${MD} {
    margin-bottom: ${theme.spacing(8)};
    margin-right: ${theme.spacing(20)};
  }
`;

const BlockColumn = styled(Block)`
  flex-direction: column;
  align-items: flex-start;
`;

const ContactIcon = styled(IconWithBackground)<{$shrinkIcons?: boolean}>`
  border-radius: 1000px;
  display: inline-flex;

  ${({ $shrinkIcons }) => $shrinkIcons ? `
    width: 3rem;
    height: 3rem;
    min-width: 3rem;

    @media ${SM}{
      width: 4rem;
      height: 4rem;
    }
  ` : `
    width: 4rem;
    height: 4rem;
  `}
`;

const ContactPhones = styled.div`
  display: flex;
  flex-direction: column;
`;

const Phones = styled(MappedPhones)`
  color: inherit;
`;

const Contacts: FC<Props> = ({ className, titleVariant, shrinkIcons }) => {
  const isXL = useMediaQuery(theme.breakpoints.up(`xl`));
  const data = useStaticQuery(
    graphql`
      query {
        markdownRemark(frontmatter: { id: { eq: "contacts" } }) {
          frontmatter {
            email
            phones
          }
        }
      }
    `
  );

  return (
    <div className={className}>
      <BlockColumn>
        <Text
          variant={titleVariant || (isXL ? `h6` : `body1`)}
          color="inherit"
          fontWeight="bold"
          css={css`
            line-height: 1.4;
          `}
        >
          Veterinář s.r.o.
        </Text>
        <Text
          variant={isXL ? `h6` : `body1`}
          fontWeight="regular"
          color="inherit"
          css={css`
            line-height: 1.4;
          `}
        >
          Spojovací 571, 541 01 Trutnov
          <br />
          IČ: 25280503
        </Text>
      </BlockColumn>

      <Block>
        <ContactIcon
          Icon={PhoneIcon}
          color={theme.palette.primary.main}
          $shrinkIcons={shrinkIcons}
          showShadow
        />
        <ContactPhones>
          <Phones
            typographyVariant={isXL ? `h6` : `body1`}
            phones={data.markdownRemark.frontmatter.phones}
            delimiter={
              <>
                ,
                <br />
              </>
            }
          />
        </ContactPhones>
      </Block>

      <Block>
        <ContactIcon
          Icon={MailIcon}
          color={theme.palette.primary.main}
          $shrinkIcons={shrinkIcons}
          showShadow
        />
        <MailLink>
          <Text
            variant={isXL ? `h6` : `body1`}
            variantMapping={{ h6: `span` }}
            color="inherit"
            fontWeight="regular"
          >
            {data.markdownRemark.frontmatter.email}
          </Text>
        </MailLink>
      </Block>

      <Block>
        <ContactIcon
          Icon={FacebookIcon}
          color={theme.palette.primary.main}
          $shrinkIcons={shrinkIcons}
          showShadow
        />
        <Link
          href="https://www.facebook.com/veterinarsro"
          title="Odkaz na náš facebook"
          target="_blank"
          rel="noopener noreferrer"
          color="inherit"
        >
          <Text
            variant={isXL ? `h6` : `body1`}
            variantMapping={{ h6: `span` }}
            color="inherit"
            fontWeight="regular"
          >
            facebook.com/veterinarsro
          </Text>
        </Link>
      </Block>
    </div>
  );
};

export default Contacts;
