import React, { FC } from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import IconWithBackground from 'components/shared/icon-with-background';
import theme from 'src/shared/theme';
import { SvgIconComponent } from '@material-ui/icons';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Text = styled(Typography)`
  margin-left: ${theme.spacing(4)};
`;

const IconSquare = styled(IconWithBackground)`
  margin: ${theme.spacing(2)};
  border-radius: 0.5rem;
  box-shadow: 0 0 0 0.5rem ${theme.palette.shadow};
`;

interface Props {
  className?: string
  color?: string;
  Icon: SvgIconComponent;
}

const SquareIconTitle: FC<Props> = (props) => (
  <Wrapper className={props.className}>
    <IconSquare Icon={props.Icon} color={props.color} />
    <Text variant="h4">{props.children}</Text>
  </Wrapper>
);

export default SquareIconTitle;
