import React, { FC } from 'react';
import { css } from 'styled-components';
import theme from 'src/shared/theme';
import SquareIconTitle from 'components/shared/square-icon-title';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import { useStaticQuery, graphql } from 'gatsby';
import { Variant } from '@material-ui/core/styles/createTypography';
import Text from 'components/shared/text';
import { XS } from 'src/shared/media-query';

interface Props {
  className?: string;
  typographyVariant?: Variant;
}

const StandardWorkingHours: FC<Props> = (props) => {
  const data = useStaticQuery(
    graphql`
      query {
        markdownRemark(frontmatter: { id: { eq: "main-info" } }) {
          frontmatter {
            workingHoursCaption
            workingHoursValues {
              days
              time
            }
          }
        }
      }
    `,
  );

  return (
    <div className={props.className}>
      <SquareIconTitle Icon={QueryBuilderIcon}>
        {data.markdownRemark.frontmatter.workingHoursCaption}
      </SquareIconTitle>
      <table
        css={css`
          margin-top: ${theme.spacing(4)};
        `}
      >
        <tbody>
          {data.markdownRemark.frontmatter.workingHoursValues.map(
            (workingHour) => (
              <tr key={workingHour.days}>
                <td
                  css={css`
                    vertical-align: top;
                  `}
                >
                  <Text
                    variant={props.typographyVariant || `h5`}
                    css={css`
                      color: ${theme.palette.primary.contrastText};
                      white-space: nowrap;
                      padding-right: ${theme.spacing(4)};
                      font-size: 1.1rem;
                      @media ${XS}{
                        font-size: 1.25rem;
                      }
                    `}
                  >
                    {workingHour.days}
                  </Text>
                </td>
                <td
                  css={css`
                    padding-bottom: ${theme.spacing(2)};
                  `}
                >
                  <Text
                    variant={props.typographyVariant || `h5`}
                    fontWeight="regular"
                    css={css`
                      color: ${theme.palette.primary.contrastText};
                      font-size: 1.1rem;
                      @media ${XS}{
                        font-size: 1.25rem;
                      }
                    `}
                  >
                    {hardenSpacesExceptAfterComma(workingHour.time)}
                  </Text>
                </td>
              </tr>
            ),
          )}
        </tbody>
      </table>
    </div>
  );
};

export default StandardWorkingHours;

const hardenSpacesExceptAfterComma = (text: string) => {
  const textWithPlaceholderCommas = text.replace(
    /, /g,
    `,gugaranga_placeholder`,
  );
  const textWithHardSpaces = textWithPlaceholderCommas.replace(/ /g, `\u00a0`);
  const textWithSoftSpacesAfterCommas = textWithHardSpaces.replace(
    /gugaranga_placeholder/g,
    ` `,
  );

  return textWithSoftSpacesAfterCommas;
};
