import React, { FC } from 'react';
import styled from 'styled-components';
import { LG, MD, SM, XL, XS } from 'src/shared/media-query';
import { SvgIconComponent } from '@material-ui/icons';

interface Sizes {
  base?: number;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
}

const IconBackground = styled.div<{
  $showShadow;
  color?: string;
  $sizes: Sizes;
}>`
  ${({ theme, color, $sizes, $showShadow }) => `
  background: ${color || theme.palette.secondary.main};
  width: ${$sizes.base}rem;
  height: ${$sizes.base}rem;
  min-width: ${$sizes.base}rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;

  ${
  $showShadow
    ? `
        box-shadow: 0 0 0 0.5rem ${theme.palette.glow};
        margin-right: ${theme.spacing(4)};
      `
    : undefined
};

${$sizes.xs &&
  `@media ${XS} {
    width: ${$sizes.xs}rem;
    height: ${$sizes.xs}rem;
    min-width: ${$sizes.xs}rem;
  }`}
${$sizes.sm &&
  `@media ${SM} {
    width: ${$sizes.sm}rem;
    height: ${$sizes.sm}rem;
    min-width: ${$sizes.sm}rem;
  }`}
${$sizes.md &&
  `@media ${MD} {
    width: ${$sizes.md}rem;
    height: ${$sizes.md}rem;
    min-width: ${$sizes.md}rem;
  }`}
${$sizes.lg &&
  `@media ${LG} {
    width: ${$sizes.lg}rem;
    height: ${$sizes.lg}rem;
    min-width: ${$sizes.lg}rem;
  }`}
${$sizes.xl &&
  `@media ${XL} {
    width: ${$sizes.xl}rem;
    height: ${$sizes.xl}rem;
    min-width: ${$sizes.xl}rem;
  }`}
`}
`;

const defaultSizes = {
  base: 2.5,
  xs: 2.5,
  sm: 3,
  md: 3,
  lg: 3,
  xl: 3,
};

const IconWithBackground: FC<{
  Icon: SvgIconComponent;
  className?: string;
  color?: string;
  showShadow?: boolean;
  sizes?: Sizes;
}> = ({ Icon, className, color, showShadow, sizes = defaultSizes }) => (
  <IconBackground
    className={className}
    color={color}
    $showShadow={showShadow}
    $sizes={{ ...sizes }}
  >
    <Icon style={{ color: `white` }} />
  </IconBackground>
);

export default IconWithBackground;
