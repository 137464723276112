import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby-theme-material-ui';
import theme from 'src/shared/theme';
import { Card, CardContent, useMediaQuery } from '@material-ui/core';
import Text from 'components/shared/text';
import StandardWorkingHours from 'components/shared/standard-working-hours';
import { LG, MD, SM, XS } from 'src/shared/media-query';
import { ABOUT, SERVICES, NEWS_PAGE, CONTACT } from 'src/shared/urls';
import { pageSideMargin } from 'src/shared/styles/page-margin';
import Contacts from 'components/shared/contacts';
import Brand from '../brand';

interface Props {
  className?: string;
}

const Container = styled.section`
  background: ${theme.palette.primary.dark};
  color: ${theme.palette.primary.contrastText};
  display: flex;
  flex-direction: column;
`;

const Navigation = styled.ul`
  list-style: none;
  justify-content: center;
  padding: 0;
  margin: 0;

  display: none;
  @media ${SM} {
    display: flex;
  }
`;

const NavigationLink = styled(Link)`
  margin: ${theme.spacing(16)};
  display: block;
  color: inherit;
`;

const Hr = styled.hr`
  border-color: ${theme.palette.glow};
  margin: 0;
`;

const Title = styled(Text)`
  margin: ${theme.spacing(8)} 0;
  display: inline-block;
`;

const Info = styled.div`
  color: inherit;
  max-width: ${theme.breakpoints.values.lg}px;
  margin: 0 auto;
  justify-content: space-between;
  flex-wrap: wrap;
  align-self: center;
  ${pageSideMargin};

  @media ${SM} {
    flex-wrap: nowrap;
    display: flex;
  }
`;

const ContactsWrapper = styled.div`
  flex: 1 1 auto;
`;

const WorkingHoursCard = styled(Card)`
  background-color: ${theme.palette.primary.main};
  flex: 0 1 316px;
  margin: ${theme.spacing(8)} 0;

  @media ${MD} {
    flex: 0 1 372px;
  }
`;

const WorkingCardContent = styled(CardContent)`
  padding: ${theme.spacing(4)};

  &:last-child {
    padding-bottom: ${theme.spacing(4)};
  }
`;

const TradeMark = styled(Text)`
  line-height: 2;
  margin-right: ${theme.spacing(4)};
  margin-top: ${theme.spacing(4)};

  @media ${XS} {
    margin-top: 0;
  }
`;

const BrandWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: ${theme.breakpoints.values.lg}px;
  margin: 0 auto;
  padding-top: ${theme.spacing(6)};
  padding-bottom: ${theme.spacing(6)};
  flex-wrap: wrap;
  align-self: center;
  ${pageSideMargin};

  @media ${XS} {
    flex-direction: row;
    padding-top: ${theme.spacing(16)};
    padding-bottom: ${theme.spacing(16)};
    justify-content: space-between;
    align-items: center;
  }
`;

const Footer: FC<Props> = props => {
  const isMD = useMediaQuery(theme.breakpoints.up(`xl`));

  return (
    <Container className={props.className}>
      <Navigation>
        <li>
          <NavigationLink to={ABOUT}>
            <Text variant="h5" color="inherit">
              O nás
            </Text>
          </NavigationLink>
        </li>
        <li>
          <NavigationLink to={SERVICES}>
            <Text variant="h5" color="inherit">
              Služby
            </Text>
          </NavigationLink>
        </li>
        <li>
          <NavigationLink to={NEWS_PAGE}>
            <Text variant="h5" color="inherit">
              Aktuality
            </Text>
          </NavigationLink>
        </li>
        <li>
          <NavigationLink to={CONTACT}>
            <Text variant="h5" color="inherit">
              Kontakt
            </Text>
          </NavigationLink>
        </li>
      </Navigation>

      <Hr />
      <Info>
        <ContactsWrapper>
          <Title variant="h4" color="inherit">
            Buďte s námi v kontaktu
          </Title>
          <Contacts
            css={css`
              display: flex;
              flex-direction: column;
              flex-wrap: wrap;

              @media ${XS} {
                flex-direction: row;
              }

              @media ${LG} {
                justify-content: space-between;
              }
            `}
          />
        </ContactsWrapper>

        <WorkingHoursCard>
          <WorkingCardContent>
            <StandardWorkingHours typographyVariant={isMD ? `h6` : `body1`} />
          </WorkingCardContent>
        </WorkingHoursCard>
      </Info>

      <Hr />
      <BrandWrapper>
        <div
          css={css`
            @media ${XS} {
              flex: 1 1 265px;
            }
          `}
        >
          <Brand white />
        </div>
        <TradeMark color="inherit">
          © Oficiální stránky Veterinář s.r.o.
        </TradeMark>
      </BrandWrapper>
    </Container>
  );
};

export default Footer;
