import React, { FC } from 'react';
import { SwipeableDrawer, Button, IconButton } from '@material-ui/core';
import HamburgerIcon from '@material-ui/icons/Menu';
import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';
import theme from 'src/shared/theme';
import HamburgerContent from './hamburger-content';

interface Props {
  className?: string;
}

const Items = styled(HamburgerContent)`
  width: 80vw;
`;

const Icon = styled(HamburgerIcon)`
  font-size: 2rem;
`;

const CloseButton = styled(IconButton)`
  align-self: flex-end;
  justify-content: center;
  height: 3rem;
  width: 3rem;
  margin-right: ${theme.spacing(2)};
  margin-top: ${theme.spacing(2)};
`;

const Drawer: FC<Props> = (props) => {
  const [opened, setOpened] = React.useState(false);

  const toggleDrawer = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    if (
      event &&
      event.type === `keydown` &&
      ((event as React.KeyboardEvent).key === `Tab` ||
        (event as React.KeyboardEvent).key === `Shift`)
    ) {
      return;
    }

    setOpened(open);
  };

  return (
    <div className={props.className}>
      <Button onClick={toggleDrawer(true)} aria-label="Otevřít navigaci">
        <Icon />
      </Button>
      <SwipeableDrawer
        anchor="right"
        open={opened}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <CloseButton onClick={toggleDrawer(false)}>
          <CloseIcon fontSize="large" />
        </CloseButton>
        <Items />
      </SwipeableDrawer>
    </div>
  );
};

export default Drawer;
