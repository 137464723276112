import React, { FC } from 'react';
import { Link, LinkProps } from '@material-ui/core';

interface Props {
  phone: string;
  className?: string;
}

const PhoneLink: FC<Props & LinkProps> = (props) => {
  const phoneWithHardSpaces = props.phone.split(` `).join(`\u00a0`);
  const unformatedPhone = props.phone.split(` `).join(``);

  return (
    <Link href={`tel:${unformatedPhone}`} color="inherit" {...props}>
      {props.children || phoneWithHardSpaces}
    </Link>
  );
};

export default PhoneLink;
