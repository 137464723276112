import { css } from 'styled-components';
import { LG, MD, SM } from '../media-query';
import theme from '../theme';

export const DEFAULT_SPACING = theme.spacing(4);

export const pageSideMargin = css`
  margin-left: ${DEFAULT_SPACING};
  margin-right: ${DEFAULT_SPACING};
  max-width: ${theme.breakpoints.values.lg}px;
  width: calc(100% - 2 * ${DEFAULT_SPACING});

  @media ${SM} {
    margin-left: ${theme.spacing(8)};
    margin-right: ${theme.spacing(8)};
    width: calc(100% - 2 * ${theme.spacing(8)});
  }

  @media ${MD} {
    margin-left: ${theme.spacing(12)};
    margin-right: ${theme.spacing(12)};
    width: calc(100% - 2 * ${theme.spacing(12)});
  }

  @media ${LG} {
    margin-left: ${theme.spacing(16)};
    margin-right: ${theme.spacing(16)};
    width: calc(100% - 2 * ${theme.spacing(16)});
  }
`;

export const pageSidePadding = css`
  padding-left: ${theme.spacing(2)};
  padding-right: ${theme.spacing(2)};
  max-width: calc(${theme.breakpoints.values.lg}px + ${theme.spacing(4)});

  @media ${SM} {
    padding-left: ${theme.spacing(8)};
    padding-right: ${theme.spacing(8)};
    max-width: calc(${theme.breakpoints.values.lg}px + ${theme.spacing(16)});
  }
`;

export const pageSectionMargin = css`
  ${pageSideMargin};
  margin-top: ${theme.spacing(12)};
  margin-bottom: ${theme.spacing(12)};

  @media ${SM} {
    margin-top: ${theme.spacing(25)};
    margin-bottom: ${theme.spacing(25)};
  }
`;
