import React, { FC } from 'react';
import { Link } from '@material-ui/core';

interface Props {
  className?: string;
  mail?: string;
}

const MailLink: FC<Props> = (props) => <Link href={`mailto:${props.mail}`} color="inherit" className={props.className}>{props.children || props.mail}</Link>;

export default MailLink;
