import { Variant } from '@material-ui/core/styles/createTypography';
import Text from 'components/shared/text';
import React, { ReactElement } from 'react';
import PhoneLink from 'src/components/shared/phone-link';
import { css } from 'styled-components';

const MappedPhones = ({
  phones,
  delimiter = `, `,
  className,
  typographyVariant = `h6`,
}: {
  phones: string[];
  delimiter?: string | ReactElement;
  className?: string;
  typographyVariant?: Variant;
}) => {
  const shouldUseDelimeter = index => phones.length - 1 > index;

  return (
    <>
      {phones.map((phone, i) => (
        <Text
          key={phone}
          className={className}
          variant={typographyVariant}
          fontWeight="medium"
          css={css`
            line-height: 1.25;
          `}
        >
          <PhoneLink phone={phone} />
          {shouldUseDelimeter(i) ? delimiter : null}
        </Text>
      ))}
    </>
  );
};

export default MappedPhones;
