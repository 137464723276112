import React, { FC } from 'react';
import styled from 'styled-components';
import {
  List, ListItem, ListItemText, Collapse,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Link, useStaticQuery, graphql } from 'gatsby';

import theme from 'src/shared/theme';
import Button from 'components/shared/button';
import {
  ABOUT, CONTACT, NEWS_PAGE, RESERVATIONS,
} from 'src/shared/urls';

interface Props {
  className?: string;
}

const CollapsedList = styled(Collapse)`
  padding-left: ${theme.spacing(4)};
`;

const Row = styled.li<{ $firstLevel }>`
  ${({ $firstLevel }) => ($firstLevel
    ? `
  font-weight: 600;
  &:not(:last-child) {
    border-bottom: 1px solid ${theme.palette.shadow};
  }`
    : null)}
`;

const ReserveButton = styled(Button)`
  margin-top: ${theme.spacing(4)};
  margin-left: ${theme.spacing(4)};
`;

const ListItemLink: FC<{
  to: string;
  text: string;
  firstLevel?: boolean;
}> = (props) => {
  const passDownProps = { ...props };
  delete passDownProps.firstLevel;

  return (
    <Row $firstLevel={props.firstLevel}>
      <ListItem button component={Link} {...(passDownProps as any)}>
        <ListItemText
          primary={props.text}
          primaryTypographyProps={{
            style: {
              fontWeight: props.firstLevel
                ? theme.typography.fontWeightMedium
                : theme.typography.fontWeightRegular,
            },
          }}
        />
      </ListItem>
    </Row>
  );
};

const HamburgerContent: FC<Props> = (props) => {
  const data = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          filter: { fileAbsolutePath: { regex: "/(/sluzby/)/" } }
        ) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                title
              }
            }
          }
        }
      }
    `,
  );

  const [servicesExpanded, setServicesExpanded] = React.useState(false);

  const expandServices = () => {
    setServicesExpanded(!servicesExpanded);
  };

  return (
    <List aria-labelledby="navigace" className={props.className} disablePadding>
      <ListItemLink to={ABOUT} text="O nás" key="O nás" firstLevel />
      <ListItemLink to={NEWS_PAGE} text="Aktuality" key="Aktuality" firstLevel />
      <ListItemLink to={CONTACT} text="Kontakt" key="Kontakt" firstLevel />

      <ListItem button onClick={expandServices}>
        <ListItemText
          primary="Služby"
          primaryTypographyProps={{
            style: {
              fontWeight: theme.typography.fontWeightMedium,
            },
          }}
        />
        {servicesExpanded ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <CollapsedList in={servicesExpanded} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {data.allMarkdownRemark.edges.map(
            ({
              node: {
                fields: { slug },
                frontmatter: { title },
              },
            }) => (
              <ListItemLink to={slug} text={title} key={slug} />
            ),
          )}
        </List>
      </CollapsedList>

      <ReserveButton to={RESERVATIONS} variant="contained" color="primary">
        Rezervovat termín
      </ReserveButton>
    </List>
  );
};

export default HamburgerContent;
