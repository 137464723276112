import React, { FC } from 'react';
import styled from 'styled-components';
import { motion, MotionProps } from 'framer-motion';
import { useMediaQuery } from '@material-ui/core';

import theme from 'src/shared/theme';
import { XS } from 'src/shared/media-query';
import Drawer from './drawer';
import Menu from './menu';

interface Props {
  className?: string;
  isShrinked: boolean;
}

const Nav = styled(motion.nav)`
  display: flex;
  align-items: center;
  color: inherit;
  justify-content: flex-end;
  max-width: 67vw;
  padding-left: ${theme.spacing(2)};
  font-weight: ${theme.typography.fontWeightMedium};

  @media ${XS} {
    flex: 0 1 800px;
    padding-left: ${theme.spacing(6)};
  }
`;

const Hamburger = styled(Drawer)`
  margin-right: -${theme.spacing(4)};
`;

const Navigation: FC<Props & MotionProps> = ({
  className,
  isShrinked,
  ...rest
}) => {
  const isWide = useMediaQuery(theme.breakpoints.up(`sm`));
  return (
    <Nav className={className} {...rest}>
      {isWide ? (
        <Menu isShrinked={isShrinked} />
      ) : (
        <Hamburger />
      )}
    </Nav>
  );
};

export default Navigation;
