import React, { useState } from 'react';
import styled from 'styled-components';
import { XS } from 'src/shared/media-query';
import theme from 'src/shared/theme';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { useMediaQuery } from '@material-ui/core';
import { pageSideMargin } from 'src/shared/styles/page-margin';
import { motion, useAnimation } from 'framer-motion';
import { useLocation } from '@reach/router';
import { Backgrounds } from '..';
import Brand from '../brand';
import Navigation from './navigation';

const MOBILE_EXPANDED_HEIGHT = `87px`;
const ROOT_PAGE_EXPANDED_HEIGHT = `137px`;
const NESTED_PAGE_EXPANDED_HEIGHT = `100px`;
const MOBILE_SHRINKED_HEIGHT = `60px`;
const ROOT_PAGE_SHRINKED_HEIGHT = `80px`;

const AppBar = styled(motion.div)`
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  background-color: white;
  display: flex;
  justify-content: center;
`;

const AppHeader = styled(motion.div)`
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: ${theme.spacing(2)} 0;
  color: ${theme.palette.text.primary};
  ${pageSideMargin};
`;

const PositionHolder = styled.div<{ $isNestedRoute }>`
  height: ${MOBILE_EXPANDED_HEIGHT};

  @media ${XS} {
    height: ${({ $isNestedRoute }) =>
    $isNestedRoute ? NESTED_PAGE_EXPANDED_HEIGHT : ROOT_PAGE_EXPANDED_HEIGHT};
  }
`;

const Header = () => {
  const isXS = useMediaQuery(theme.breakpoints.up(`xs`));
  const [shrinked, setShrinked] = useState(false);
  const location = useLocation();
  const control = useAnimation();
  const isNestedRoute = location.pathname.trim() !== `/`;

  let shrinkedHeight;
  let grownHeight;

  if (isXS) {
    shrinkedHeight = ROOT_PAGE_SHRINKED_HEIGHT;
  } else {
    shrinkedHeight = MOBILE_SHRINKED_HEIGHT;
  }

  if (isXS) {
    grownHeight = isNestedRoute
      ? NESTED_PAGE_EXPANDED_HEIGHT
      : ROOT_PAGE_EXPANDED_HEIGHT;
  } else {
    grownHeight = MOBILE_EXPANDED_HEIGHT;
  }

  useScrollPosition(
    ({ currPos: { y: curr } }) => {
      if (curr < -50) {
        if (shrinked) return;
        control.start({
          height: shrinkedHeight,
          transition: { duration: 0.7 },
        });
        setShrinked(true);
      } else {
        if (!shrinked) return;
        control.start({
          height: grownHeight,
          transition: { duration: 0.7 },
        });
        setShrinked(false);
      }
    },
    [shrinked, isXS, location],
    undefined,
    false,
    100
  );

  return (
    <>
      <PositionHolder $isNestedRoute={isNestedRoute} />
      <AppBar>
        {isXS ? <Backgrounds /> : null}
        <AppHeader
          style={{
            height: grownHeight,
          }}
          animate={control}
        >
          <Brand />
          <Navigation isShrinked={shrinked} />
        </AppHeader>
      </AppBar>
    </>
  );
};

export default Header;
