import React, { FC } from 'react';
import styled from 'styled-components';
import CssBaseline from '@material-ui/core/CssBaseline';
import Header from 'components/shared/layout/header';
import theme from 'src/shared/theme';
import Hat from 'components/shared/hat';
import { useMediaQuery } from '@material-ui/core';
import { LG } from 'src/shared/media-query';
import Footer from './footer';
import StylesWrapper from './styles-wrapper';

const Main = styled.main`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const BackgroundClipper = styled.div`
  width: 100%;
  height: 1500px;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

const BackgroundHolder = styled.div`
  position: relative;
  left: 0;
  margin: 0 auto;

  @media ${LG} {
    left: calc((100% - ${theme.breakpoints.values.lg}px) / 2);
  }
`;

const Background = styled.img`
  position: absolute;
  top: 0;
  left: 272px;
  transform-origin: left;
  transform: scaleX(1.2);
  z-index: -1;
`;

export const Backgrounds: FC<{ className?: string }> = props => {
  const isXS = useMediaQuery(theme.breakpoints.up(`xs`));
  return (
    <>
      {isXS ? (
        <BackgroundClipper className={props.className}>
          <BackgroundHolder>
            <Background
              src="/assets/welcome-background.svg"
              aria-hidden="true"
            />
          </BackgroundHolder>
        </BackgroundClipper>
      ) : null}
    </>
  );
};

const Layout = ({ children, pageName = `` }) => (
  <StylesWrapper>
    <CssBaseline />
    <React.StrictMode>
      <Hat pageName={pageName} />
      <Backgrounds />
      <Header />
      <Main>{children}</Main>
      <Footer />
    </React.StrictMode>
  </StylesWrapper>
);

export default Layout;
