import React, { Suspense, FC } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby-theme-material-ui';

import { SM } from 'src/shared/media-query';
import Button from 'components/shared/button';
import { ABOUT, CONTACT, NEWS_PAGE, RESERVATIONS } from 'src/shared/urls';
import theme from 'src/shared/theme';

const ServicesDropdown = React.lazy(() => import(`./services-dropdown`));

interface Props {
  className?: string;
  isShrinked: boolean;
}

export const menuItemStyling = css`
  font-weight: ${theme.typography.fontWeightMedium};
  font-size: inherit;
  color: ${theme.palette.text.primary};

  &:hover {
    text-decoration: none;
    color: ${theme.palette.primary.main};
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;
`;

const ReserveButton = styled(Button)<{ $isShrinked }>`
  line-height: 1;
  font-size: inherit;
  ${({ $isShrinked }) =>
    $isShrinked ? `padding: ${theme.spacing(3)} ${theme.spacing(7)};` : null};
  transition: padding 700ms, background-color 200ms;
`;

const MenuLink = styled(Link)`
  ${menuItemStyling};
`;

const Ul = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1 1 auto;
  justify-content: space-between;
`;

const Li = styled.li`
  margin: 0 ${theme.spacing(2)};
  flex: 1 1 auto;
  text-align: center;

  @media ${SM} {
    &:first-of-type {
      margin-left: 0;
    }
    &:last-of-type {
      margin-right: 0;
      flex: 0 0 auto;
    }
  }
`;

const ServicesFallback = () => (
  <div
    css={css`
      ${menuItemStyling};
    `}
  >
    Služby
  </div>
);

const Menu: FC<Props> = ({ className, isShrinked }) => {
  const isSSR = typeof window === `undefined`;
  return (
    <Wrapper className={className}>
      <Ul>
        <Li>
          <MenuLink to={ABOUT}>O nás</MenuLink>
        </Li>
        <Li>
          {isSSR ? <ServicesFallback /> : (
            <Suspense
              fallback={
                <ServicesFallback />
              }
            >
              <ServicesDropdown />
            </Suspense>
          )}
        </Li>
        <Li>
          <MenuLink to={NEWS_PAGE}>Aktuality</MenuLink>
        </Li>
        <Li>
          <MenuLink to={CONTACT}>Kontakt</MenuLink>
        </Li>
        <Li>
          <ReserveButton
            to={RESERVATIONS}
            variant="contained"
            color="primary"
            $isShrinked={isShrinked}
          >
            Rezervovat termín
          </ReserveButton>
        </Li>
      </Ul>
    </Wrapper>
  );
};

export default Menu;
